
import React from 'react'
import { allServices, resumeJobs } from '../projects'
import { Grid, Chip } from '@mui/material'
import '../styles/AboutPage.scss'
import { Link } from 'react-router-dom'

export const AboutPage = () => {
    return (
        <Grid className='Home AboutPage' container >
            <Grid item xs={12}>
                <h1>Welcome to Esteban Inc.</h1>
            </Grid>
            <Grid item xs={10}>
                <h2> I'm Emylee Esteban - a multidisciplinary designer, developer & artist.</h2>
                <p>  From design and development to artistry, let’s bring your vision to life with innovative, high-quality solutions tailored to meet the unique needs of businesses and individuals. </p>
            </Grid>
            <Grid item xs={12} className='aboutServices'>
                <h3>SERVICES</h3>
                <Grid container justifyContent={'center'} spacing={2}>
                    {
                        allServices.map((service, serviceInd) => {
                            return (
                                <Grid item sm={12} lg={6} xl={4}>
                                    <Link to="/contact" key={serviceInd + "_Chip"}>
                                        <Chip key={serviceInd + "_Chip"} size="large" variant='outlined' sx={{ width: '100%' }} label={<div>
                                            <div className='chipTitle'>{service.designTitle}</div>
                                            <div className='chipHover'> {service.hoverText}</div>
                                        </div>} className='serviceChip' />
                                    </Link>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Grid item xs={10} sx={{ marginBottom: '25px' }}>
                <h3>Gamer &#128126; + Comic Book Enthusiast &#129497;&#8205;&#9792;&#65039; + Bacon Lover &#129363; + Lego Collector &#129521;+ Birder &#x1F9A4; + Sweet Tooth &#127852; + Crime Junkie &#128269;+ Computer Nerd &#128187; + Horror Movie Buff &#128128;  + Powder Seeker &#127935; </h3>
            </Grid>
            <Grid className='resume section' flexDirection={'column'}>
                <h3>Clients</h3>
                <Grid item container spacing={2} className='resumeList' justifyContent={'center'}>
                    {resumeJobs.map(job => (
                        <Grid item xs={12} lg={3} key={job.id + "_company"} className='resumeBtn'>
                            <div className='hover-content'>
                                <div className='company'>{job.company}</div>
                                <div className='title'>{job.title}</div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}