import { Grid } from '@mui/material'
import React from 'react'
import { allServices, projects } from '../projects'
import '../styles/ServicePage.scss'

export const ServicePage = ({ setShowGallery, setSelectedProject, setProjIndex }) => {
    return (
        <div className='Home Services'>
            <h1> Services</h1>

            <Grid container className='serviceContainer'>
                {
                    allServices.map((service, serviceInd) => {
                        return (
                            <Grid item
                                className='serviceBox'
                                display="flex"
                                justifyContent={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                                flexDirection={'column'}
                                key={service.id + "_box_" + serviceInd} xs={12}>
                                <h2>
                                    {service.designTitle}
                                </h2>

                                <ul className='serviceList'>
                                    {
                                        service.serviceLists.map((list, listInd) => {
                                            return (
                                                <li key={list.trim() + "_serviceChip_" + listInd}>{list}</li>
                                            )
                                        })
                                    }
                                </ul>
                                <Grid container justifyContent={'center'} className='projHolder'>
                                    {
                                        projects.filter(x => x.subjectID === service.id).map((project, projInd) => {
                                            return (
                                                <Grid item key={project.id}

                                                    className='serviceProj' onClick={() => {

                                                        setProjIndex(projInd)
                                                        setSelectedProject(project)
                                                        setShowGallery(true)
                                                    }}>

                                                    <div className='projectBGImage' style={{ backgroundImage: 'url("' + project.image + '")' }}>

                                                    </div>
                                                    <div className='projTitle'>{project.title}</div>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }

            </Grid>
        </div>
    )
}