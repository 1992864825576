import { Chip, Grid } from '@mui/material'
import React, { useState } from 'react'
import { allServices, projects } from '../projects'
import '../styles/PortfolioPage.scss'

export const PortfolioPage = ({ setShowGallery, setSelectedProject, setProjIndex }) => {
    const [filteredProjects, setFilteredProjects] = useState(projects)
    const [filter, setFilter] = useState(null)

    return (
        <div className='portfolioPage Home'>
            <h1> Portfolio</h1>
            <ul className='filterPortfolioList'>
                <li className={!filter ? 'active' : ''} onClick={() => {
                    setFilteredProjects(projects)
                    setFilter(null)
                }}>All</li>
                {
                    allServices.map((service, serviceInd) => {
                        return (
                            <li
                                className={filter && filter === service.id ? 'active' : ''}
                                key={service.id + "_service_" + serviceInd}
                                onClick={() => {
                                    let updatedProjects = [...projects]
                                    updatedProjects = updatedProjects.filter(x => x.subjectID === service.id)
                                    setFilter(service.id)
                                    setFilteredProjects(updatedProjects)
                                }} >
                                {service.designTitle}
                            </li>
                        )
                    })
                }
            </ul>
            <div>
                <Grid container className='gallery'>
                    {filteredProjects.map((project, projInd) => (
                        <Grid xs={12} md={3} className='gallery-box box square'
                            key={"project_" + project.id}
                            onClick={() => {
                                setProjIndex(projInd)
                                setSelectedProject(project)
                                setShowGallery(true)
                            }}
                        >
                            <div className='title'>
                                <h4>
                                    {project.title}
                                </h4>
                                <p>
                                    {
                                        project.services.map((service, serviceIndex) => {
                                            return <Chip label={service} key={serviceIndex} variant='outlined' sx={{ margin: '3px' }} />
                                        })
                                    }
                                </p>
                            </div>
                            <div className='projectBGImage' style={{ backgroundImage: 'url("' + project.image + '")' }}>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}