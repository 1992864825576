import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import whiteLogo from './images/EstebanInc-Logo-White.png'
import { Home } from './components/Home';
import { Resume } from './components/Resume'
import Loading from './components/Loading';
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material';
import { BrowserRouter, Routes, Route, NavLink, Link } from 'react-router-dom';
import { ErrorPage } from './components/ErrorPage';
import { PortfolioPage } from './components/PortfolioPage';
import { ServicePage } from './components/ServicePage';
import { AboutPage } from './components/AboutPage';
import { ContactPage } from './components/ContactPage';
import { Box, Chip, DialogTitle, FormControl, FormControlLabel, Paper, Stack, Switch } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { projects } from './projects';
import './styles/App.scss'
import ScrollToTop from './components/ScrollToTop';


export default function App() {
  const [loading, setLoading] = useState(true)
  const [showGallery, setShowGallery] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const [projIndex, setProjIndex] = useState(99)
  const [toggleBefore, setToggleBefore] = useState(false)

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500)
  }, [])
  if (loading) {
    return <Loading />
  }
  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* <Helmet>
        <title>Esteban Inc. | Multidisciplinary Designer, Developer & Artist | Creative Solutions Across All Media</title>

        <meta name="description" content="Esteban Inc. is a multidisciplinary, one-woman show offering a comprehensive range of creative services. From design and development to artistry, let’s bring your vision to life with innovative, high-quality solutions tailored to meet the unique needs of businesses and individuals alike. Create something extraordinary with Esteban Inc." />

        <meta name="keywords" content="multidisciplinary designer, developer, artist, creative services, one-woman show, Esteban Inc., innovative solutions, high-quality design, comprehensive creative services, business solutions, individual services, tailored design, creative development, artistic solutions, ui, ux, front end developer, denver, graphic designer, logo design, branding, front-end development, unique designs, handmade, handmade work, fine arts, denver artist, denver designer" />

        <meta property="og:title" content="Esteban Inc. | Multidisciplinary Designer, Developer & Artist | Creative Solutions Across All Media" />
        <meta property="og:description" content="Discover Esteban Inc., a multidisciplinary, one-woman show offering creative services tailored to your unique needs. Let's bring your vision to life with innovative solutions across all media." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://esteban-inc.com" />
        <meta property="og:image" content="/images/E-INC-LOGO.webp" />

        <meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Multidisciplinary Designer, Developer & Artist | Steban Inc." />
<meta name="twitter:description" content="Steban Inc. offers comprehensive creative services to meet your unique needs. Let’s create something extraordinary together." />
<meta name="twitter:image" content="[Link to Your Featured Image]" />
      </Helmet> */}
      <div className="App wrapper">
        <nav className={'menuNav'}>
          <Grid container justifyContent={'space-between'}
            alignContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'}
                alignContent={'center'} alignItems={'center'}>
                <Grid item xs={12} sm={3} className={'headerLink'}>
                  <NavLink title='Esteban Inc.' name="Esteban Inc."
                    to='/'>
                    <div className="headerNavTitle" onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                      })
                    }}>
                      ESTEBAN INC.
                    </div>
                  </NavLink>

                </Grid>
                <Grid item xs={12} sm={6} className='menu-links' display={'flex'} justifyContent={'flex-end'}>
                  <NavLink title='About' name="About"
                    to='/'
                    className={({ isActive }) =>
                      isActive ? "menu-link-anchor active" : "menu-link-anchor"
                    }>Home</NavLink>

                  <NavLink title='Portfolio' name='Portfolio'
                    to='/portfolio'
                    className={({ isActive }) =>
                      isActive ? "menu-link-anchor active" : "menu-link-anchor"
                    }>Portfolio</NavLink>
                  <NavLink className={({ isActive }) =>
                    isActive ? "menu-link-anchor active" : "menu-link-anchor"
                  } title='Services' name='Services'
                    to='/services'>
                    Services
                  </NavLink>
                  <NavLink className={({ isActive }) =>
                    isActive ? "menu-link-anchor active" : "menu-link-anchor"
                  } title='About' name="About"
                    to='/about'>About</NavLink>

                  <NavLink className={({ isActive }) =>
                    isActive ? "menu-link-anchor active" : "menu-link-anchor"
                  } title='Contact' name="Contact"
                    to='/contact'>Contact</NavLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </nav>

        <Dialog
          open={showGallery}
          onClose={() => setShowGallery(false)}
          fullScreen
          scroll='paper'
          PaperComponent={Paper}
          className='galleryDialog'>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignContent: "center", alignItems: 'center', borderBottom: '2px solid black' }}>
            <div className='modal-title'>

              {selectedProject ? selectedProject.title : ''}

            </div>
            <div className='largeGalleryNav'>
              <IconButton
                className='navBtns'
                variant='contained'
                sx={{
                  marginRight: '10px'
                }}
                disabled={projIndex === 0 ? true : false}
                size='small'
                title='Previous Project'
                aria-label='Previous Project'
                onClick={() => {
                  let newProjInd = projIndex - 1
                  setSelectedProject(projects[newProjInd])
                  setProjIndex(newProjInd)
                  setToggleBefore(false)
                }}>
                <ChevronLeft fontSize='large' />
              </IconButton>
              <IconButton
                className='navBtns'
                variant='contained'
                size='small'
                disabled={projIndex === projects.length - 1 ? true : false}
                title='Next Project'
                aria-label='Next Project'
                sx={{
                  marginLeft: '10px'
                }}
                onClick={() => {
                  let newProjInd = projIndex + 1
                  setSelectedProject(projects[newProjInd])
                  setProjIndex(newProjInd)
                  setToggleBefore(false)
                }}>
                <ChevronRight fontSize='large' />
              </IconButton>
              <IconButton size='large' aria-label='Close Button' onClick={(e) => setShowGallery(false)}>
                <Close fontSize='large' />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent dividers={true}>
            <Grid container className='mobileProjectNav'>

              <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                <IconButton
                  variant='contained'
                  sx={{
                    marginRight: '10px'
                  }}
                  disabled={projIndex === 0 ? true : false}
                  size='small'
                  title='Previous Project'
                  aria-label='Previous Project'
                  onClick={() => {
                    let newProjInd = projIndex - 1
                    setSelectedProject(projects[newProjInd])
                    setProjIndex(newProjInd)
                    setToggleBefore(false)
                  }}>
                  <ChevronLeft fontSize='large' />
                </IconButton>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                <IconButton
                  variant='contained'
                  size='small'
                  disabled={projIndex === projects.length - 1 ? true : false}
                  title='Next Project'
                  aria-label='Next Project'
                  sx={{
                    marginLeft: '10px'
                  }}
                  onClick={() => {
                    let newProjInd = projIndex + 1
                    setSelectedProject(projects[newProjInd])
                    setProjIndex(newProjInd)
                    setToggleBefore(false)
                  }}>
                  <ChevronRight fontSize='large' />
                </IconButton>
              </Grid>

            </Grid>
            {selectedProject ? (
              <Grid container className='project-holder' spacing={2} justifyContent={'center'} alignContent={'flex-start'} alignItems={'flex-start'}>
                <Grid item xs={12} lg={3} id='headerInfo'>
                  <Stack className='infoStack'>
                    <p>
                      {selectedProject.caption}
                    </p>
                    <Box sx={{
                      marginBottom: '10px'
                    }}>
                      {
                        selectedProject.services.map((service, serviceIndex) => {
                          return <Chip label={service} key={serviceIndex} variant='outlined' sx={{ margin: '3px' }} />
                        })
                      }
                    </Box>
                    {selectedProject.siteLink ?
                      <p> <a href={selectedProject.siteURL} target='_blank'>
                        View Site</a></p>
                      : null}
                    {selectedProject.purchaseLink ?
                      <p>
                        <a href={selectedProject.purchaseLink} target='_blank'>
                          Purchase</a>
                      </p>
                      : null}
                    {selectedProject.seeBefore ?
                      <p>
                        <FormControlLabel control={<Switch checked={toggleBefore} onChange={(e) => { setToggleBefore(e.target.checked) }} />} label="See Before" />
                      </p>
                      : null}

                  </Stack>
                </Grid>
                {selectedProject.subjectID === 6 ? (
                  <Grid item className='galleryVid' xs={12} lg={8}>
                    <video width="100%" height="100%" controls loading='lazy'>
                      <source src={selectedProject.video} type="video/mp4" />
                    </video>
                  </Grid>
                ) : (
                  <Grid item xs={12} lg={8} className='gallery'>
                    {
                      selectedProject && selectedProject.seeBefore && toggleBefore ?
                        <Grid container spacing={2} justifyContent={'center'}>
                          {selectedProject.beforeGallery.map((img, imgInd) => (
                            <Grid key={imgInd + "_imageInd"} item className='project-image'>
                              <LazyLoadImage src={img} width={'100%'} alt={'An image for the ' + selectedProject.title + " displaying a part of the project."} />
                            </Grid>
                          ))}
                        </Grid>
                        :
                        <Grid container spacing={2} justifyContent={'center'}>
                          {selectedProject.gallery.map((img, imgInd) => (
                            <Grid key={imgInd + "_imageInd"} item className='project-image'>
                              <LazyLoadImage src={img} width={'100%'} alt={'An image for the ' + selectedProject.title + " displaying a part of the project."} />
                            </Grid>
                          ))}
                        </Grid>
                    }

                  </Grid>
                )}
              </Grid>
            ) : null}
          </DialogContent>
        </Dialog>

        <Routes>
          <Route path='/' element={<Home setShowGallery={setShowGallery} setSelectedProject={setSelectedProject} setProjIndex={setProjIndex} />} />
          <Route path='/portfolio' element={<PortfolioPage setShowGallery={setShowGallery} setSelectedProject={setSelectedProject} setProjIndex={setProjIndex} />} />
          <Route path='/services' element={<ServicePage setShowGallery={setShowGallery} setSelectedProject={setSelectedProject} setProjIndex={setProjIndex} />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>

        <footer>
          <Grid container className='contactFooter' id="contact">
            <Grid item xs={12} className='enable-animation'>
              <div className='marquee'>
                <div className='marquee__content' aria-hidden="true">
                  <div className='contactMarquee marquee__item el email'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                  <div className='contactMarquee marquee__item el linkedIn'>     <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                  <div className='contactMarquee marquee__item el insta' >    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                  <div className='contactMarquee marquee__item el etsy'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>

                  <div className='contactMarquee marquee__item el tiktok'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>

                  <div className='contactMarquee marquee__item el resumeid'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                </div>
                <div className='marquee__content' aria-hidden="true">
                  <div className='contactMarquee marquee__item el email'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                  <div className='contactMarquee marquee__item el linkedIn'>     <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                  <div className='contactMarquee marquee__item el insta' >    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                  <div className='contactMarquee marquee__item el etsy'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>

                  <div className='contactMarquee marquee__item el tiktok'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>
                  <div className='contactMarquee marquee__item el resumeid'>
                    <Link to='/contact'>Let's Chat &#128489;</Link>
                  </div>

                </div>

              </div>
            </Grid>

            {/* <Grid item xs={12}>
              <div className='clientChip noClient'>😞 Currently Closed to New Clients 😞</div>
            </Grid> */}

            <Grid item xs={12} lg={12} className='footerInfo'>
              <Grid container>
                <Grid item xs={12} lg={3}>
                  <ul className='info contactInfo'>
                    <li>
                      <img src={whiteLogo} width={50} />
                    </li>

                    <li className='email'>  <a href="mailto:emylee@esteban-inc.com" target="_blank" title='Mail Link to Emylee@Esteban-inc.com'>EMYLEE@ESTEBAN-INC.COM</a></li>
                    <li>  <div id='siteTag'>Site designed &amp; implemented by me. </div></li>
                    <li>
                      <div className="coFlag">
                        <div id="flag"></div>
                        <div id="circle"></div>
                      </div>
                      <div style={{ marginLeft: '30px' }}>Denver, Co</div>
                    </li>

                    {/* <li>
                      <div className='clientChip noClient'>😞 Closed to New Clients 😞</div>
                    </li> */}
                  </ul>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <ul className='social-links info'>

                    <li><a href='https://estebaninc.etsy.com' title='Etsy' target='_blank'>Etsy</a></li>
                    <li><a href='https://www.instagram.com/esteban.inc/' title='Instagram' target='_blank'>Instagram</a></li>
                    <li><a href='https://www.tiktok.com/@esteban_inc' title='TikTok' target='_blank'>TikTok</a></li>
                    <li><a href='https://www.linkedin.com/in/emyleeesteban/' title='LinkedIn' target='_blank'>LinkedIn</a></li>

                    <li><NavLink to='/resume'>
                      Resume
                    </NavLink></li>
                  </ul>

                </Grid>
                <Grid item xs={6} lg={3}>
                  <ul className='siteMap'>
                    <li>  <a href="/" target="_blank" title='Home'>Home</a></li>
                    <li><a href='/portfolio' title='Portfolio Page' target='_blank'>Portfolio</a></li>
                    <li><a href='/services' title='Services Page' target='_blank'>Services</a></li>
                    <li><a href='/about' title='About Page' target='_blank'>About</a></li>
                    <li><a href='/contact' title='Contact Page' target='_blank'>Contact</a></li>
                  </ul>
                </Grid>
              </Grid>
              <div className='copyright'>
                <div>
                  ESTEBAN INC.
                </div>
                <div>
                  &copy; 2025
                </div>

              </div>
            </Grid>
          </Grid>
        </footer>
      </div>
    </BrowserRouter>
  )
}
