import alienVsPredator from './images/art/Alien-vs-Predator-Painting/Alien-vs-Predator-Collage.webp'
import bcCards from './images/graphicdesign/Illustrations/Business-Card-Illustration-Front-and-Back.webp'
import bcCards2 from './images/graphicdesign/Illustrations/Business-Card-Illustration-Lineup.webp'
import donnieDarko from './images/art/Donnie-Darko-Painting/Donnie-Darko-Painting.webp'
import spidermanVsVenom from './images/art/Spiderman-vs-Venom-Painting/Spiderman-vs-Venom-Painting.webp'
import wonderwoman from './images/art/Wonder-Woman-vs-Mogul-Mixed-Media/Wonder-Woman-vs-Mogul-Mixed-Media.webp'

import molding1 from './images/web/KONG/KONG-Molding-Dashboard.webp'
import molding2 from './images/web/KONG/KONG-Molding-Press-Charts.webp'
import molding3 from './images/web/KONG/KONG-Preventative-Maintenance.webp'
import molding4 from './images/web/KONG/KONG-Randomizer-Home.webp'

import platefulCover from './images/project-covers/plateful.webp'
import plateful1 from './images/web/Plateful/Plateful-HomePage.webp'
import plateful2 from './images/web/Plateful/Plateful-Homepage-Slide2.webp'
import plateful3 from './images/web/Plateful/Plateful-HiddenFood-Quote.webp'
import plateful4 from './images/web/Plateful/Plateful-MobileApp-SplashPage.webp'

import vidaCover from './images/project-covers/vida.webp'
import vida from './images/web/STARZ-Vida/STARZ-Vida-Synopsis.webp'
import vida2 from './images/web/STARZ-Vida/STARZ-Vida-Character-Hover1.webp'
import vida3 from './images/web/STARZ-Vida/STARZ-Vida-Character-Detail2.webp'
import vida4 from './images/web/STARZ-Vida/STARZ-Vida-Character-Hover2.webp'

import vt1 from './images/graphicdesign/Virginia-Tech/Virginia-Tech-Year-in-Review-Booklet.webp'
import vt2 from './images/graphicdesign/Virginia-Tech/Virginia-Tech-Year-In-Review-Booklet-Spread.webp'
import vt3 from './images/graphicdesign/Virginia-Tech/Virginia-Tech-Year-in-Review-Booklet-QuoteSpread.webp'
import vt4 from './images/graphicdesign/Virginia-Tech/Virginia-Tech-Womens-Club-Lacrosse-Senior-Poster.webp'
import vt5 from './images/graphicdesign/Virginia-Tech/Virginia-Tech-Womens-Club-Lacrosse-Fundraising-T-Shirts-Layout.webp'
import vt6 from './images/graphicdesign/Virginia-Tech/Virginia-Tech-Mens-Lacrosse-Season-Poster.webp'
import vt7 from './images/graphicdesign/Virginia-Tech/Virginia-Tech-Womens-Club-Lacrosse-Fundraising-T-Shirts-Layout.webp'

import aved1 from './images/motiongraphics/STARZ-Ash-vs-Evil-Dead-Cover.webp'
import aved2 from './images/motiongraphics/STARZ-Ash-vs-Evil-Dead-SuperPAC-Cover.webp'
import cps from './images/motiongraphics/STARZ-Counterpart-Video-Cover.webp'
import sct from './images/motiongraphics/SCT-Video-Reel-Cover.png'

import aved1vid from './images/motiongraphics/STARZ-Ash-vs-Evil-Dead-Presskit-Video.mp4'
import aved2vid from './images/motiongraphics/STARZ-Ash-vs-Evil-Dead-SuperPAC-Video.mp4'
import cpsvid from './images/motiongraphics/STARZ-Counterpart-Video.mp4'
import sctvid from './images/motiongraphics/SCT-Video-Reel.mp4'

// import logoCover from './images/graphicdesign/Logofolio/logofolio.png'
// import susMov from './images/graphicdesign/Logofolio/susmove.png'
// import wcla from './images/graphicdesign/Logofolio/wclalogo.png'

import wwCover from './images/project-covers/ww.webp'
import venomCover from './images/project-covers/venom.webp'
import predCover from './images/project-covers/pred.webp'
import bcCover from './images/project-covers/Illustration-and-Business-Card-Cover.webp'
import kongCover from './images/project-covers/KONG-Web-Application-Dashboard.webp'
import bizCover from './images/project-covers/biz.webp'
import beetleCover from './images/project-covers/Wood-Carved-Scarab-Beetle-Cover.webp'
import beetleSide from './images/art/Hand-Carved-Beetle/Hand-Carved-Beetle-Side-View.webp'
import beetleFront from './images/art/Hand-Carved-Beetle/Hand-Carved-Beetle-Front-View.webp'
// import selfServeCover from './images/project-covers/selfservef.webp'


import avp1 from './images/art/Alien-vs-Predator-Painting/Alien-vs-Predator-Predator-Closeup.webp'
import avp2 from './images/art/Alien-vs-Predator-Painting/Alien-vs-Predator-Alien-Closeup.webp'

import bcCards1 from './images/graphicdesign/Illustrations/Business-Card-Illustration-Lineup2.webp'

import pizza1 from './images/art/Pepperoni-Pizza-Skateboard/Pepperoni-Pizza-Skateboard-Laid-in-Grass.webp'
import pizza2 from './images/art/Pepperoni-Pizza-Skateboard/Pepperoni-Pizza-Skateboard-Closeup.webp'
import venomboard1 from './images/art/Venom-vs-Carnage-Skateboard/Venom-vs-Carnage-Skateboard-Overview.webp'
import venomboard2 from './images/art/Venom-vs-Carnage-Skateboard/Venom-vs-Carnage-Closeup.webp'

import denverShineCover from './images/web/Denver-Shine-Co/Denver-Shine-Co-Project-Cover.webp'
import denvershineHome from './images/web/Denver-Shine-Co/Denver-Shine-Co-HomePage.webp'
import officePickUp from './images/web/Denver-Shine-Co/Denver-Shine-Co-OnSiteService.webp'
import deliveryPage from './images/web/Denver-Shine-Co/Denver-Shine-Co-ProcessPage.webp'


import logoIcon from './images/services/Logo-and-Branding-Pen-Tool.svg'
import graphicsIcon from './images/services/Graphic-Design-Layout-Image-Placeholder.svg'
import userExpIcons from './images/services/User-Interface-and-User-Experience-Responsive-Image.svg'
import codeIcons from './images/services/User-Interface-and-User-Experience-Image-Filler.svg'
import artsIcons from './images/services/Fine-Arts-Palette.svg'
import motionIcons from './images/services/Motion-Graphics-Background.svg'

import rcgImage from './images/web/RCG-Global-Services/Web-RCG-Homepage.webp'
import rcgImage1 from './images/web/RCG-Global-Services/Web-RCG-Homepage-2.webp'
import rcgImage2 from './images/web/RCG-Global-Services/Web-RCG-Homepage-3.webp'
import rcgImage3 from './images/web/RCG-Global-Services/Web-RCG-IndustryPage.webp'
import rcgImage4 from './images/web/RCG-Global-Services/Web-RCG-ServicesPage.webp'

import rcgBeforeImg1 from './images/web/RCG-Global-Services/Web-RCG-Preupgrade-Homepage.webp'
import rcgBeforeImg2 from './images/web/RCG-Global-Services/Web-RCG-Preupgrade-Homepage-2.webp'
import rcgBeforeImg3 from './images/web/RCG-Global-Services/Web-RCG-Preupgrade-Services.webp'

import turtleIllustration from './images/graphicdesign/Illustrations/Illustration_Chillin_Turtle.webp'
import sustainableLogo from './images/graphicdesign/Logofolio/Logofolio-Sustainable-Movement.webp'
import vtLogo from './images/graphicdesign/Logofolio/Logofolio-Virginia-Tech.webp'
import itIllustration from './images/graphicdesign/Illustrations/Illustration_Horror_Movies_IT.webp'
import chicken from './images/graphicdesign/Illustrations/Illustration_Halloween_Chicken.webp'
import denverParkTrust from './images/graphicdesign/Logofolio/Logofolio-Denver-Park-Trust.webp'


import susMvmtCover from './images/web/SustainableMovement/Sustainable-Movment-Logo-Cover.webp'
import susMvmtHomePage from './images/web/SustainableMovement/Sustainable-Movement-Home-1.webp'
import susMvmtHomePage2 from './images/web/SustainableMovement/Sustainable-Movement-Home-2.webp'
import susMvmtBanner from './images/web/SustainableMovement/Sustainable-Movement-Banner.webp'
import susMvmtPrograms from './images/web/SustainableMovement/Sustainable-Movement-Programs.webp'
import susMvmtFAQs from './images/web/SustainableMovement/Sustainable-Movement-FAQs.webp'
//24
export const projects = [
    {
        title: 'Sustainable Movement Website',
        id: 24,
        subjectID: 3,
        selected: true,
        image: susMvmtCover,
        gallery: [susMvmtHomePage, susMvmtHomePage2, susMvmtBanner, susMvmtPrograms, susMvmtFAQs],
        siteLink: true,
        siteURL: 'https://www.sustainablemvmnt.com/',
        caption: "Worked closely with a Denver based fitness coach to understand her vision, preferences, and goals for her online presence. Together, we discussed what content resonated with her and what didn’t, ensuring the final design aligned with her brand. Using Figma, I created mockups to bring her ideas to life and developed her website on Squarespace. The launch of the site coincided with the debut of her new online program, featuring a bold and dynamic brand identity with striking oranges and blues. I also designed her logo to reflect her unique style and message.",
        services: ["Web Design", "Square Space", "UI & UX", "Brand Identity"]
    }, {
        title: 'Hand-Carved Scarab Beetle',
        id: 23,
        subjectID: 5,
        selected: true,
        image: beetleCover,
        gallery: [beetleFront, beetleSide],
        caption: 'A wooden scarab beetle that I hand-carved and hand painted.',
        services: ["Fine Arts", "Wood Working", "Acrylic Painting"],
        purchaseLink: "https://www.etsy.com/listing/1783190064/hand-carved-colorful-scarab-beetle-wall?click_key=55511fdceda6310dbf92baba1a4e966dbaa57d03%3A1783190064&click_sum=54e00a97&ref=shop_home_active_4&frs=1"
    }, {
        title: 'RCG Global Services Website',
        id: 22,
        subjectID: 3,
        selected: true,
        image: rcgImage,
        gallery: [rcgImage, rcgImage1, rcgImage2, rcgImage3, rcgImage4],
        siteLink: true,
        siteURL: 'https://rcgglobalservices.com',
        caption: "Working on a tight deadline I redesigned and implemented a new RCG website through Hubspot. We wanted to convey a dynamic, refreshing, brighter UI to push people to want to work with RCG and invite them to dive deeper into the services offered. During the web rebrand I collaborated with the Marketing team on a brand guide, business cards, advertising materials, and social media content.",
        services: ['Web Design', "Hubspot", "Brand Identity", "Content Management"],
        seeBefore: true,
        beforeGallery: [rcgBeforeImg1, rcgBeforeImg2, rcgBeforeImg3]
    }, {
        title: 'KONG Manufacturing App',
        id: 1,
        subjectID: 4,
        image: kongCover,
        selected: true,
        gallery: [molding1, molding2, molding3, molding4],
        caption: "Developed a manufacturing floor management app that provides real-time insights into machine statistics and operations. Integrated with Twilio, the app sends instant alerts to supervisors when machine settings are altered or when critical values are exceeded. The application also connects directly to machinery for real-time data monitoring, tracks maintenance schedules, and manages shift-related tasks and operator assignments, ensuring streamlined and efficient operations.",
        services: ["Web Application Design", "Front End Development", "UI & UX", "React", "HTML"]
    }, {
        title: 'Venom vs. Carnage',
        id: 19,
        subjectID: 5,
        image: venomboard2,
        selected: true,
        gallery: [venomboard1, venomboard2],
        caption: 'A custom-painted skateboard deck of two iconic characters mashed together: Venom and Carnage.',
        services: ["Fine Arts", "Acrylic Painting"]
    }, {
        title: 'Vida Digital Press Kit',
        id: 3,
        subjectID: 4,
        image: vidaCover,
        selected: true,
        gallery: [vida, vida2, vida3, vida4],
        caption: "An interactive guide to the world of 'Vida.' The experience starts with a commercial introducing the show and its characters. Through vibrant colors and impactful production images, the essence of the show is carried through the site.",
        services: ["Web Design", "Front End Development", "UI & UX", "React", "HTML"]
    },
    // {
    //     title: 'OTA Bulleit Production',
    //     id: 10,
    //     subjectID: 4,
    //     image: cps,
    //     video: null,
    //     gallery: [],
    //     caption: ''
    // }, 
    {
        title: 'Denver Shine Co. Website',
        id: 20,
        subjectID: 3,
        selected: false,
        image: denverShineCover,
        gallery: [denvershineHome, officePickUp, deliveryPage],
        siteLink: true,
        siteURL: 'https://denvershineco.com',
        caption: "In collaboration with a Denver-based small business, we undertook a comprehensive website redesign aimed at modernizing its online presence. We integrated more contemporary design visuals, messaging to underscore the company's commitment to sustainability, and optimized intake forms for enhanced experience and conversions.",
        services: ["Web Design", "Wix", "UI & UX"]
    },

    // {
    //     title: 'KONG Self-Service',
    //     id: 17,
    //     subjectID: 1,
    //     image: selfServeCover,
    //     selected: false,
    //     gallery: [ss0, ss1, ss2, ss3],
    //     caption: "Designed to streamline the process of manually removing products from inventory, our self-service solution allows users to scan and check out inventory items. At the close of each day, an automated order is initiated to synchronize this data with the KONG inventory application."
    // },
    {
        title: 'Plateful Website',
        id: 2,
        subjectID: 3,
        image: platefulCover,
        selected: false,
        gallery: [plateful1, plateful2, plateful3, plateful4],
        caption: "Designed and developed a website for Plateful, a healthcare and nutrition company, to provide a sneak peek into their upcoming application. The site is interactive, with hidden hover features that engage users and encourage exploration. The clean, modern design reflects Plateful’s innovative approach to health and nutrition",
        services: ["Web Design", "Square Space", "UI & UX"]
    },

    // {
    //     title: "Survivor's Remorse Press Kit",
    //     id: 15,
    //     subjectID: 1,
    //     image: srsCover,
    //     selected: false,
    //     gallery: [srs1, srs2, srs3],
    //     caption: "A simpler and more sophisticated themed interactive guide for the show 'Survivor's Remorse,' designed to capture the show's essence through minimalist textures and a color palette of gold and black."
    // },
    {
        title: 'Virginia Tech Marketing Materials',
        id: 8,
        subjectID: 2,
        image: vt3,
        selected: true,
        gallery: [vt1, vt2, vt3, vt5, vt6, vt7],
        caption: "From t-shirts designed for fundraising and team spirit to promotional posters and a year-in-review booklet, each piece reflects a cohesive and vibrant visual identity.",
        services: ["Graphic Design", "Brand Identity", "Marketing Materials"]
    }, {
        title: 'Ash Vs. Evil Dead Mogo',
        id: 9,
        subjectID: 6,
        image: aved1,
        video: aved1vid,
        selected: true,
        gallery: [],
        caption: 'Produced a motion graphic video of the printed press kit for Ash vs. Evil Dead. This two-minute video brings the pages of the press kit to life, combining dynamic visuals with storytelling. The video is included on a USB within the physical press kit packaging, offering a modern and engaging way to experience the content.',
        services: ["Motion Design"]
    },

    // {
    //     title: 'Marketing',
    //     id: 11,
    //     subjectID: 1,
    //     image: bizCover,
    //     gallery: [marketing1],
    //     caption: "Collection of marketing assets for various clients."
    // },
    {
        title: 'Logos & Illustrations',
        id: 5,
        subjectID: 2,
        image: bcCover,
        selected: false,
        gallery: [bcCards, bcCards1, denverParkTrust, turtleIllustration, chicken, sustainableLogo, vtLogo, itIllustration],
        caption: "A collection of illustrations and logos.",
        services: ["Fine Art", "Graphic Design", "Illustrations"]
    },
    {
        title: 'Donnie Darko',
        id: 6,
        subjectID: 5,
        image: donnieDarko,
        gallery: [donnieDarko],
        selected: false,
        caption: 'This painting draws inspiration from the cult classic film Donnie Darko. Different parts of the movie are integrated into this piece using monochromatic tones.',
        services: ["Fine Art", "Acrylic Painting"]
    },
    {
        title: 'Spiderman vs.Venom',
        id: 7,
        subjectID: 5,
        selected: false,
        image: venomCover,
        gallery: [spidermanVsVenom],
        caption: 'Created two companion pieces featuring Venom and Spider-Man, each painted on a 12x24 inch repurposed picture frame. Designed to work as a pair or stand alone.',
        services: ["Fine Art", "Acrylic Painting"]
    },
    {
        title: 'Wonder Woman vs. Mogul',
        id: 16,
        subjectID: 5,
        image: wwCover,
        selected: false,
        gallery: [wonderwoman],
        caption: 'A 12x36 mixed media painting of a fight between Wonder Woman vs. Mogul.',
        services: ["Fine Art", "Spray Paint", "Acrylic Painting"]
    }, {
        title: 'Alien vs. Predator',
        id: 4,
        subjectID: 5,
        image: predCover,
        selected: false,
        gallery: [alienVsPredator, avp1, avp2],
        caption: 'Painted a scene depicting the tense moment before a battle between a Xenomorph and a Predator on a 12x24 inch canvas. Using acrylics, I captured the intensity and iconic imagery of these sci-fi legends, making it a striking piece for fans of the genre.',
        services: ["Fine Art", "Acrylic Painting"]
    },
    {
        title: 'Ash For President Campaign',
        id: 13,
        subjectID: 6,
        image: aved2,
        video: aved2vid,
        selected: false,
        gallery: [],
        caption: "One of three for a social media campaign for Ash vs. Evil Dead.",
        services: ["Motion Design"]
    }
    , {
        title: 'Pizza Pizza Pizza',
        id: 18,
        subjectID: 5,
        image: pizza2,
        selected: false,
        gallery: [pizza1, pizza2],
        caption: 'A pepperoni pizza themed skateboard.',
        services: ["Fine Art", "Acrylic Painting"]
    },
    // {
    //     title: 'Presentation Graphics Reel',
    //     id: 14,
    //     subjectID: 3,
    //     image: sct,
    //     video: sctvid,
    //     gallery: []
    // },
]

export const subjects = [
    {
        subject: 'web',
        subjectID: 1
    }, {
        subject: 'design',
        subjectID: 2
    }, {
        subject: 'art',
        subjectID: 4
    }, {
        subject: 'motion graphics',
        subjectID: 3
    }
]


export const resumeJobs = [
    {
        "id": 5,
        "company": "RCG Global Services",
        "dates": "April 2019 - Current",
        "title": "UI/UX Designer",
        "link": "https://www.RCGGlobalServices.com",
        "list": [
            {
                "listID": 0,
                "listDescription": "Led the development of UI designs, interactive prototypes, and mockups based on in-depth client feedback and user research."
            },
            {
                "listID": 1,
                "listDescription": "Collaborated with a cross-functional design team to create an innovative healthcare certification tracking system."
            },
            {
                "listID": 2,
                "listDescription": "Partnered with the marketing team to launch a rebranded website, ensuring consistent branding across all marketing materials."
            }
        ]
    },
    {
        "id": 0,
        "company": "KONG Company",
        "dates": "April 2019 - Current",
        "link": "https://www.kongcompany.com",
        "title": "Web Applications Developer",
        "list": [
            {
                "listID": 0,
                "listDescription": "Engineered software solutions to streamline business operations and processes."
            },
            {
                "listID": 1,
                "listDescription": "Developed web applications using HTML5, React, and JavaScript, improving user experience and functionality."
            },
            {
                "listID": 2,
                "listDescription": "Integrated front-end technologies with web APIs, MS SQL Server, and MS Azure for dynamic data interactions."
            }
        ]
    },
    {
        "id": 1,
        "company": "Strategy Consulting Team",
        "dates": "January 2016 - Current",
        "title": "Graphic Designer",
        "link": "https://strategyconsultingteam.com/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Produced high-quality marketing assets including flyers, motion graphics, presentations, and proposal designs for clients."
            },
            {
                "listID": 1,
                "listDescription": "Designed internal graphics for company-wide distribution and presentations."
            },
            {
                "listID": 2,
                "listDescription": "Collaborated with project leaders to deliver creative assets using the full Adobe Creative Suite."
            }
        ]
    },
    {
        "id": 9,
        "company": "Denver Fan Expo",
        "title": "Exhibitor",
        "link": "https://fanexpohq.com/fanexpodenver/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Created and sold original artwork inspired by pop culture characters and themes at Denver Fan Expo."
            }
        ]
    },
    {
        "id": 3,
        "company": "STARZ Entertainment",
        "dates": "May 2016 - March 2018",
        "title": "Interactive Designer",
        "link": "https://starz.com/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Developed cross-platform websites, adhering to brand guidelines for various TV shows."
            },
            {
                "listID": 1,
                "listDescription": "Designed and implemented multi-platform email and social media campaigns using Photoshop, Illustrator, and HTML."
            },
            {
                "listID": 2,
                "listDescription": "Created motion graphics for promotional content and marketing initiatives using After Effects."
            }
        ]
    },
    {
        "id": 2,
        "company": "Viecure",
        "dates": "March 2018 - April 2019",
        "title": "Front-End Developer",
        "link": "https://viecure.com/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Transformed design concepts into responsive web applications using Angular 5 and TypeScript."
            },
            {
                "listID": 1,
                "listDescription": "Collaborated with QA teams to enhance user experience and address system issues."
            }
        ]
    },
    {
        "id": 4,
        "company": "PoolDawg",
        "dates": "February 2016 - May 2016",
        "title": "Graphic Designer",
        "link": "https://pooldawg.com/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Redesigned and maintained online marketing materials, training articles, and product imagery."
            },
            {
                "listID": 1,
                "listDescription": "Created posters, marketing materials, and motion graphics to enhance brand visibility."
            },
            {
                "listID": 2,
                "listDescription": "Assisted in product photoshoots, delivering creative assets for marketing campaigns."
            }
        ]
    },
    {
        "id": 8,
        "company": "Logicstop",
        "title": "Front End Developer, UI/UX",
        "link": "https://logicstop.com/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Provided front-end development support and conducted UX research for web applications, followed by full implementation."
            }
        ]
    },
    {
        "id": 10,
        "company": "Denver Park Trust",
        "title": "Logo Design",
        "link": "https://denverparktrust.org/product/4th-anniversary-mug/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Designed the logo for Denver Park Trust's 4th anniversary, reflecting their mission and values."
            }
        ]
    },
    {
        "id": 12,
        "company": "Denver Shine Co",
        "title": "Web Design",
        "link": "https://www.denvershineco.com/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Revamped website design with a modern aesthetic, enhancing UX and UI to highlight sustainability efforts."
            }
        ]
    },
    {
        "id": 13,
        "company": "Sustainable Movement",
        "title": "Logo Design",
        "link": "https://www.thumbtack.com/co/denver/personal-trainers/sustainable-movement/service/333170224058228860",
        "list": [
            {
                "listID": 0,
                "listDescription": "Collaborated with the owner to create a new logo for their personal training business, aligning with their brand vision."
            }
        ]
    },
    {
        "id": 11,
        "company": "Out of the Ashes",
        "title": "Painter",
        "link": "https://outoftheashproductions.com/",
        "list": [
            {
                "listID": 0,
                "listDescription": "Commissioned to create custom painted logos and themed artwork for various events and venues."
            }
        ]
    }
]


export const designList = [{
    id: 1,
    title: 'Starters',
    designs: [
        {
            id: 1,
            designTitle: 'Logo (your way)',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 2,
            designTitle: 'Logo (my way)',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 3,
            designTitle: 'Business Cards',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 4,
            designTitle: 'Stickers',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 5,
            designTitle: 'Flyer/Brochure/Billboard/Ad',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 6,
            designTitle: 'Social Media Post',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }
    ]
}, {
    id: 2,
    title: 'Main',
    designs: [
        {
            id: 1,
            designTitle: 'Brand Package',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 2,
            designTitle: 'Landing Page',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 3,
            designTitle: 'Landing Page w/ Host',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 4,
            designTitle: 'Full Website (5+ pages)',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 5,
            designTitle: 'Website Redesign',
            price: 100,
            desc: "Can be served 2 ways, with or without code"
        }, {
            id: 6,
            designTitle: 'UI/UX Research',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 7,
            designTitle: 'Custom Application Design',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }
    ]
}, {
    id: 3,
    title: 'Desserts',
    designs: [
        {
            id: 1,
            designTitle: 'Tattoo Design',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 2,
            designTitle: 'Hand-Crafted Item',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 3,
            designTitle: 'Painting/Illustration/Mixed Media Art',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }, {
            id: 4,
            designTitle: 'Garden Weeding',
            price: 100,
            desc: "Lorem Ipsum dolor"
        }
    ]
}]


export const allServices = [
    //     {
    //     id: 1,
    //     designTitle: 'Logo Design & Branding',
    //     price: 100,
    //     desc: "Lorem Ipsum dolor",
    //     alias: 'logo',
    //     icon: logoIcon
    // },
    {
        id: 2,
        designTitle: 'Graphic Design',
        price: 100,
        desc: "Lorem Ipsum dolor",
        alias: 'graphics',
        icon: graphicsIcon,
        serviceLists: ["Brand Identity & Logo Design", "Data Visualization", "Print Design", "Marketing & Advertising Assets", "Infographics", "Pitch Decks & Slideshows", "Social Media Banners & Graphpics"],
        hoverText: "\u2712 Let's Design \u2712"
    }, {
        id: 3,
        designTitle: 'UI & UX',
        price: 100,
        desc: "Lorem Ipsum dolor",
        alias: 'user',
        icon: userExpIcons,
        serviceLists: [
            "Wireframing & Prototyping",
            'Web Design',
            "User Research & Testing",
            "Business Analysis",
            "Information Architecture",
            "Redesigns",
            "Design Systems"
        ],
        hoverText: "\uD83C\uDFAF Let's Iterate \uD83C\uDFAF"
    }, {
        id: 4,
        designTitle: 'Front End Dev',
        price: 100,
        desc: "Lorem Ipsum dolor",
        alias: 'code',
        icon: codeIcons,
        serviceLists: [
            "Website Development", "Web Application Development", "Responsive Web Design", "Web Optimization", "SEO Optimization", "Performance Audits"
        ],
        hoverText: "\u2328 Let's Code \u2328"
    }, {
        id: 5,
        designTitle: 'Fine Arts',
        price: 100,
        desc: "Lorem Ipsum dolor",
        alias: 'arts',
        icon: artsIcons,
        serviceLists: ["Custom Artwork", "Wood Working", "Acrylic Painting", "Print Making", "Wood Burning", "Custom Signage", "Commission Requests", "Custom Illustration", "Digital Art"],
        hoverText: "\uD83D\uDD8C Let's Create \uD83D\uDD8C"
    }, {
        id: 6,
        designTitle: 'Motion Design',
        price: 100,
        desc: "Lorem Ipsum dolor",
        alias: 'motion',
        icon: motionIcons,
        hoverText: "\uD83C\uDFA5 Let's Animate \uD83C\uDFA5",
        serviceLists: ['Logo Animation', "Lower Thirds & Callouts", "Intros/Outro Animations", "Kinetic Typography", "GIFS", "Animated Icons", "Video Editing", "Motion Graphics"]
    }]


export const wordList = [
    "Graphic DESIGNER",
    "front-end DEVELOPER",
    "Mixed Media ARTIST",
    "Jack of All Trades",
    "Multidisciplinary Creative",
    "Versatile Virtuoso",
    "Multi-talented Maven",
    "Swiss-Army Knife of Creativity",
    "Creative Chameleon",
    "Dynamic Design Dynamo",
    "All Purpose Creative",
    "Maestro of Many Mediums",
    "Ubiquitous Artisan",
    "Queen of Creativity",
    "Creative Connoisseur"
]

export const adjectives = [
    "Extraordinary",
    "Exceptional",
    "Elegant",
    "Eclectic",
    "Eloquent",
    "Engaging",
    "Experienced",
    "Energetic",
    "Expressive",
    "Enthusiastic",
    "Elevated",
    "Enduring",
    "Evolving",
    "Exemplary",
    "Effortless",
    "Enigmatic"
];